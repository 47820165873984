import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
// Assets
import LogoImg from "../../assets/img/imageedit_11_6190648486.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
            <img src={LogoImg} alt="logo" style={{ width: "8em"}} />
             
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className=" font13">Kareebi</span> All Right Reserved
            </StyleP>
              <Link className="whiteColor  pointer font13" onClick={()=>window.open("/terms-and-conditions")} href="/terms-and-conditions" smooth={true} offset={-80}>
              Terms and Conditions
            </Link>
        
            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;