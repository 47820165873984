import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import CheckMark from "../../assets/svg/Checkmark";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import RollerIcon from "../../assets/svg/Services/RollerIcon";

export default function PricingTable({ icon, price, title, text,  offers, action }) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        <p className="font18 bold">Starts at {price}</p>
      </div>
      <div style={{ margin: "0em 0 1em" }}>
        <h4 className="font30 extraBold">{title} Plan</h4>
        <br/>
        
      </div>
      <div style={{minHeight: "12em"}}>
        {offers
          ? offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.checked ? (
                    <div style={{ minWidth: "20px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font20 bold">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      <div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>
      <ButtonInput type="submit" value="Contact Us" className="pointer animate radius8" style={{ maxWidth: "220px", marginBottom: "5em" }} onClick={() => window.open("https://wa.me/919625108285?text=I%27m+interested+in+your+"+title+"+plan+and+want+to+know+more")} />
      
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const ButtonInput = styled.input`
  border: 1px solid #123D90;
  background-color: #123D90;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #123DC0;
    border: 1px solid #123D90;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;