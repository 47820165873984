import React, {Suspense} from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import TermsAndConditions from "./screens/TermsAndConditions.jsx";

const Routes = [
  {
    slug: "/",
    component: Landing,
  },
  {
    slug: "/terms-and-conditions",
    component: TermsAndConditions,
  },
];

function Routing() {
  return (
    <Switch>
      {Routes.map((route, index) => {
        return (
          <Route
            exact
            key={index}
            path={route.slug}
            component={withRouter(() => (
              <route.component />
            ))}
          />
        );
      })}
    </Switch>
  );
}
export default function App() {
  return  <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <Routing />
            </Suspense>
          </BrowserRouter>
        
  // return (
  //   <>
  //     <Helmet>
  //       <link rel="preconnect" href="https://fonts.googleapis.com" />
  //       <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  //       <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
  //     </Helmet>
  //     <Landing />
  //     <TermsAndConditions />
  //   </>
  // );
}

