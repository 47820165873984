import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import Header from "../components/Sections/Header";
import Pricing from "../components/Sections/Pricing";
import Services from "../components/Sections/Services";
import AppDemo from "../assets/img/appDemo.png";
import AppComingSoon from "../components/App/comingSoon";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <AppComingSoon/>
      <Services />
      {/*<Projects />
      <Blog />*/}
      <Pricing />
      <Contact />
      <Footer />
    </>
  );
}


