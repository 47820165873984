import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Plans</h1>
            
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
          <TableBox>
            <PricingTable
              icon="roller"
              price="$35/month"
              title="Karefree"
              text="Empower your parents with the tools they need to navigate the digital world confidently. From connecting with family to using apps like Uber and Zomato, Karefree provides essential tech assistance for their daily needs."
              offers={[
                { name: "Digital Literacy Sessions", checked: true },
                { name: "Tech Assistance", checked: true },
                { name: "24/7 Support", checked: true },
                { name: "App Tutorials", checked: true },
                { name: "Emergency Assistance", checked: true },
              ]}
              action={() => alert("clicked")}
            />
          </TableBox>
          <TableBox>
            <PricingTable
              icon="monitor"
              price="$65/month"
              title="Kompanion"
              text="Ensure your parents have a trusted companion for regular visits and heartfelt support. Kompanion provides emotional support and social interaction to keep them engaged and happy."
              offers={[
                { name: "Companion Visits", checked: true },
                { name: "Emotional Support", checked: true },
                { name: "Social Engagement Activities", checked: true },
                { name: "24/7 Tech Support", checked: true },
                
              ]}
              action={() => alert("clicked")}
            />
          </TableBox>
          <TableBox>
            <PricingTable
              icon="browser"
              price="$115/month"
              title="Keeper"
              text="Give your parents the ultimate care package with Keeper. Combining all features of Kompanion with regular doctor consultations and comprehensive health management for their well-being and your peace of mind."
              offers={[
                { name: "Companion Visits", checked: true },
                { name: "Emotional Support", checked: true },
                { name: "Social Engagement Activities", checked: true },
                { name: "24/7 Tech Support", checked: true },
                { name: "Regular Doctor Consultations", checked: true },
              ]}
              action={() => alert("clicked")}
            />
          </TableBox>
        </TablesWrapper>
        
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 1em;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




