import React, { useState, useEffect } from 'react';
import AppDemo from '../../assets/img/appDemo.png';
import styled from "styled-components";

const AppComingSoon = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const containerStyle = {
    marginBottom: '5em',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // height: isMobile ? 'auto' : '100vh', // Ensure full height on larger screens
  };

  const sectionStyle = {
    padding: '2em',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: isMobile ?'center':'left', // Center the text
  };

  const imgStyle = {
    maxWidth: '100%',
    height: 'auto',
  };

  return (
    <div className="lightBg" style={containerStyle}>
      <div  style={sectionStyle}>
        <div>
          <h5 className="font40 extraBold">Kareebi App</h5>
          <p className="font20 semiBold">
          Only for Our Exclusive Members
          </p>
          <br/>
          <p className="font18">
          Designed for the few who care the most. The Kareebi app is now live — but only for those who’ve already joined us. Your personalized connection to seamless care for your loved ones is just a tap away.
          </p>
          <br/>
          <p className="font18">
      Haven’t signed up yet? Don’t miss your chance to be part of something special. Get early access to Kareebi and experience it before the world does.
    </p>
          <br/>
          <ButtonInput type="submit" value="Unlock Early Access" className="pointer animate radius8" 
          style={{ maxWidth: "220px", marginBottom: "5em" }} 
          onClick={() => window.open("https://wa.me/919625108285?text=I%27m+interested+in+understanding+how+Kareebi+App+works")} 
        />
        </div>
       
      </div>
      <div  style={sectionStyle}>
        <img src={AppDemo} alt="coming-soon" style={imgStyle} />
      </div>
    </div>
  );
};

const ButtonInput = styled.input`
  border: 1px solid #123D90;
  background-color: #123D90;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #123DC0;
    border: 1px solid #123D90;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

export default AppComingSoon;
