import React from "react";
import styled from "styled-components";
// Assets

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font16 " style={{ marginTop: "1em"}}>
            Whether you’re looking for personalized digital assistance for your loved ones or seeking reliable care services, Kareebi is here to help. We provide tailored solutions to ensure your family stays connected and cared for, no matter the distance.

            
              <br />
              <br />
              Reach out to us today and discover how we can support your family’s needs!
            </p>
          </HeaderInfo>
         
                <ButtonInput type="submit" value="Say Hi! on Whatsapp" className="pointer animate radius8" style={{ maxWidth: "220px", marginBottom: "5em" }} onClick={() => window.open("https://wa.me/919625108285?text=I%27m+interested+in+Kareebi+and+want+to+know+more")} />
              
            
            
          
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #123D90;
  background-color: #123D90;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #123DC0;
    border: 1px solid #123D90;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









